/* eslint-disable quote-props, no-dupe-keys, max-len */

import { toRem, toRems } from '../../helpers'

// @TODO: THEME NET
const style = ({ theme }) => {
    const config = {
        sm: {
            swiper: {
                width: '100%',
                margin: `${toRem(50)} 0 0 0`
            },
            item: {
                width: '60%'
            },
            icon: {
                left: 0
            },
            graphic: {
                left: toRem(-20),
                bottom: toRem(-20)
            }
        },
        md: {
            swiper: {
                width: '100%',
                margin: `${toRem(50)} 0 0 0`
            },
            item: {
                width: '23%'
            },
            icon: {
                right: 0
            },
            graphic: {
                right: toRem(-20),
                bottom: toRem(-20)
            },
            logo: {
                left: 'calc((100%-300px)/2))'
            }
        }
    }

    return {
        position: 'relative',
        width: '100%',
        marginTop: toRem(30),
        marginBottom: toRem(20),
        [theme.breakpoints.up('md')]: {
            maxWidth: toRem(1140),
            margin: 'auto'
        },
        '.listalicaProizvoda': {
            '&_swiper': {
                width: config.sm.swiper.width,
                margin: config.sm.swiper.margin,
                [theme.breakpoints.up('md')]: {
                    width: config.md.swiper.width,
                    margin: config.md.swiper.margin
                }
            },
            '&_item': {
                width: config.sm.item.width,
                height: 'unset',
                [theme.breakpoints.up('md')]: {
                    // width: config.md.item.width
                    maxWidth: toRem(270)
                }
            },
            '&_slider': {
                width: 'calc(100% - 30px)',
                height: '0',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '40%',
                zIndex: 10,

                '.article__listalica &': {
                    width: '100%'
                }
            },
            '&_disclaimer': {
                fontSize: toRem(16),
                marginTop: toRem(20),
                [theme.breakpoints.down('sm')]: {
                    padding: toRems([0, 10])
                }
            }
        },
        '.swiper-button-disabled': {
            opacity: 0,
            pointerEvents: 'none'
        }
    }
}

export default style
